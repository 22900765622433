import React, { useEffect, useState } from "react";
import styles from "./styles.module.scss";
import { NavLink, useLocation, useNavigate } from "react-router-dom";

import logo from "../../../assets/svgs/logos/logo_blue.svg";
import { MdClose } from "react-icons/md";
import { AiOutlineMenu } from "react-icons/ai";

import arrow1 from "../../../assets/svgs/icons/media_arrow2.svg";
import { useSelector, useDispatch } from "react-redux";

import {
  OpenNavbarMainAction,
  CloseNavbarsAction,
  OpenNavbarSecondAction,
} from "../../../redux/navbars/navbars.reducer";
import { InscrireBtn } from "../../../components/buttons/Buttons";

function MainNavbar() {
  // const [opened, setopened] = useState(false);
  const opened = useSelector((state) => state.NavbarReducer.open_main);
  const opened2 = useSelector((state) => state.NavbarReducer.open_second);
  const navig = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();

  const Open = () => {
    dispatch(OpenNavbarMainAction());
  };

  const inscription = () => {
    navig("/Inscription");
  };

  const OpenOther = () => {
    dispatch(OpenNavbarSecondAction());
  };

  const Close = () => {
    dispatch(CloseNavbarsAction());
  };

  const ToHome = () => {
    navig("/home");
  };

  useEffect(() => {
    Close();
  }, [location]);

  return (
    <div className={`${styles["main"]} ${opened && styles["opened"]}`}>
      {/* {!opened && !opened2 && (
        <div className={styles["berger"]} onClick={Open}>
          <AiOutlineMenu className={styles["icon"]} />

          <img     src={logo} alt="" onClick={ToHome} />
        </div>
      )} */}
      <div className={styles["left"]}>
        <MdClose className={styles["close"]} onClick={Close} />
        <img src={logo} alt="" onClick={ToHome} />
        <span className={styles["link"]}>
          <NavLink to="/admission">ADMISSION</NavLink>
        </span>
        <span className={styles["link"]}>
          <NavLink to="/campus"> CAMPUS</NavLink>
        </span>
        <span className={styles["link"]}>
          <NavLink to="/formations"> FORMATIONS</NavLink>
        </span>
        {/* <span className={styles["link"]}>
          <NavLink to="/alternance"> ALTERNANCE</NavLink>
        </span> */}
        <span className={styles["link"]}>
          <NavLink to="/international"> INTERNATIONAL</NavLink>
        </span>
        <span className={styles["link"]}>
          <NavLink to="/entreprise"> ENTREPRISE</NavLink>
        </span>
        <span className={styles["link"]}>
          <NavLink to="/contactus"> CONTACT</NavLink>
        </span>
        <span className={styles["link"]}>
          <NavLink to="https://intranet.uik.ens.tn/UIK_Web" target="_blank">
            INTRANET
          </NavLink>
        </span>

        <InscrireBtn className={styles.btn} onClick={inscription}>
          S’INSCRIRE
        </InscrireBtn>

        <div className={styles["more"]} onClick={OpenOther}>
          <span>Nos Ecoles</span>
          <img src={arrow1} className={styles.arrow} alt="" />
        </div>
      </div>
    </div>
  );
}

export default MainNavbar;
