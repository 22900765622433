import React from "react";
import styles from "./styles.module.scss";

import logo_blue from "../../../assets/svgs/logos/logo_blue.svg";
import logo_orange from "../../../assets/svgs/logos/logo_orange.svg";
import logo_red from "../../../assets/svgs/logos/logo_red.png";
import logo_pink from "../../../assets/svgs/logos/logo_pink.svg";
import logo_purple from "../../../assets/svgs/logos/logo_purple.svg";
import logo from "../../../assets/svgs/logos/logo_blue.svg";

import { InscrireBtn } from "../../../components/buttons/Buttons";
import { NavLink, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import { MdClose } from "react-icons/md";
import { AiOutlineMenu } from "react-icons/ai";
import {
  CloseNavbarsAction,
  OpenNavbarMainAction,
  OpenNavbarSecondAction,
} from "../../../redux/navbars/navbars.reducer";

import arrow1 from "../../../assets/svgs/icons/media_arrow2.svg";

function SecondNavbar({ isCoched = false, isCoched2 = false }) {
  const dispatch = useDispatch();
  const theme = useSelector((state) => state.ColorReducer.theme);
  const opened = useSelector((state) => state.NavbarReducer.open_second);
  const opened2 = useSelector((state) => state.NavbarReducer.open_second);
  const navigation = useNavigate();

  const inscription = () => {
    navigation("/Inscription");
  };

  const OpenOther = () => {
    dispatch(OpenNavbarMainAction());
  };

  const Close = () => {
    dispatch(CloseNavbarsAction());
  };

  const ToHome = () => {
    navigation("/home");
  };

  const Open = () => {
    dispatch(OpenNavbarSecondAction());
  };

  return (
    <div
      className={`
      ${styles.main} 
      ${isCoched && styles.coched} 
      ${isCoched2 && styles.coched2}
      ${opened && styles["opened"]}`}
    >
      {!opened && !opened2 && (
        <div className={styles["berger"]}>
          <AiOutlineMenu className={styles["icon"]} onClick={Open} />

          <img src={logo} alt="" onClick={ToHome} />
        </div>
      )}

      <MdClose className={styles["close"]} onClick={Close} />

      <NavLink to="/home">
        {theme === "theme-blue" && (
          <img src={logo_blue} alt="UIK UNIVERSITE IBN KHALDOUN" />
        )}
        {theme === "theme-yellow" && (
          <img src={logo_orange} alt="UIK UNIVERSITE IBN KHALDOUN" />
        )}
        {theme === "theme-red" && (
          <img src={logo_red} alt="UIK UNIVERSITE IBN KHALDOUN" />
        )}
        {theme === "theme-pink" && (
          <img src={logo_pink} alt="UIK UNIVERSITE IBN KHALDOUN" />
        )}
        {theme === "theme-purple" && (
          <img src={logo_purple} alt="UIK UNIVERSITE IBN KHALDOUN" />
        )}
      </NavLink>
      <NavLink to="/university/informatique">
        <span>INFORMATIQUE</span>
      </NavLink>
      <NavLink to="/university/architecture">
        <span>ARCHITECTURE</span>
      </NavLink>
      <NavLink to="/university/design">
        <span>DESIGN</span>
      </NavLink>
      <NavLink to="/university/droit">
        <span>DROIT</span>
      </NavLink>
      <NavLink to="/university/buiness">
        <span>BUSINESS</span>
      </NavLink>
      <InscrireBtn className={styles.btn} onClick={inscription}>
        S’INSCRIRE
      </InscrireBtn>
      <div className={styles["more"]} onClick={OpenOther}>
        <img src={arrow1} className={styles.arrow} alt="" />
        <span>Voir plus</span>
      </div>
    </div>
  );
}

export default SecondNavbar;
