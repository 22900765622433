import { toast } from "react-hot-toast";
import sha256 from "crypto-js/sha256";

export const init_data = {
  Civilite: "", //
  Prenom: "", //
  Nom: "", //
  Email: "", //
  Telephone: "", //
  adresse: "", //
  pays: "", //
  nationalite: "", //
  Niveau: "", //
  Filiere: "Diplôme National d’Architecte", //
  reponse: "", //
  Message: "", //
};

export const validation = (form = init_data) => {
  if (!form.Civilite) {
    toast.error("Civilite est obligatoire");
    return false;
  }

  if (!form.Prenom) {
    toast.error("Prenom est obligatoire");
    return false;
  }

  if (!form.Nom) {
    toast.error("Nom est obligatoire");
    return false;
  }

  if (!form.Email) {
    toast.error("Email est obligatoire");
    return false;
  }

  if (!form.Telephone) {
    toast.error("Telephone est obligatoire");
    return false;
  }

  if (!form.pays) {
    toast.error("Pays est obligatoire");
    return false;
  }

  if (!form.nationalite) {
    toast.error("Nationalite est obligatoire");
    return false;
  }

  if (!form.Niveau) {
    toast.error("Niveau est obligatoire");
    return false;
  }

  if (!form.Filiere) {
    toast.error("Filiere est obligatoire");
    return false;
  }

  if (!form.reponse) {
    toast.error("Comment avez-vous connu l'UIK");
    return false;
  }

  return true;
};

export const trackFacebookPixel = (event_name, event_params) => {
  if (window.fbq) {
    window.fbq("track", event_name, event_params);
  }
};

export const trackFacebookConversionsAPI = (
  event_name,
  user_data,
  custom_data
) => {
  const accessToken =
    "EAAOa1eJevaMBO1YGSBw7NLZBPXCX2nLEI0lUxc5Cod4PRYfmVobLZAhk2jsIqI3NZAz4y5XBaNgWnMCvaoKA3Tq3mgJqhoAvph1Ia4XdUgb86aDPKrpWZBlnD8JrL3ZA4bW3ADbZBZBk5F51MpXSqApgTjZBNUUwvlj0p5CaZBTO2SuMEFqCu2AmXJrHA02YySpBSbAZDZD";
  const pixelId = "1193761191766664";

  // Hash user data
  const hashedUserData = {
    em: sha256(user_data.em).toString(),
    ph: sha256(user_data.ph).toString(),
  };

  fetch(
    `https://graph.facebook.com/v10.0/${pixelId}/events?access_token=${accessToken}`,
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        data: [
          {
            event_name: event_name,
            event_time: Math.floor(new Date() / 1000),
            user_data: hashedUserData,
            custom_data: custom_data,
            action_source: "website",
          },
        ],
      }),
    }
  )
    .then((response) => response.json())
    .then((data) => {
      console.log("Success:", data);
    })
    .catch((error) => {
      console.error("Error:", error);
    });
};
