import React from "react";
import styles from "./styles.module.scss";

import { FaSnapchatGhost, FaFacebook } from "react-icons/fa";
import { BsYoutube, BsTwitter, BsLinkedin } from "react-icons/bs";
import { GrInstagram } from "react-icons/gr";

function Footer() {
  const GoToFacebook = () => {
    window.open("https://www.facebook.com/UikUniversiteIbnKhaldoun", "_blank");
  };

  const GoToYouTube = () => {
    window.open(
      "https://www.youtube.com/@uik-universiteibnkhaldoun7065",
      "_blank"
    );
  };

  const GoToInstag = () => {
    window.open(
      "https://www.instagram.com/uikuniversiteibnkhaldoun/?hl=fr",
      "_blank"
    );
  };

  return (
    <div className={styles["main"]}>
      <h3>© Université Ibn Khaldoun</h3>
      <div className={styles["icons"]}>
        <FaFacebook onClick={GoToFacebook} className={styles["icon"]} />
        <GrInstagram onClick={GoToInstag} className={styles["icon"]} />
        {/* <BsLinkedin className={styles["icon"]} /> */}
        {/* <FaSnapchatGhost className={styles["icon"]} /> */}
        {/* <BsTwitter className={styles["icon"]} /> */}
        <BsYoutube onClick={GoToYouTube} className={styles["icon"]} />
      </div>
    </div>
  );
}

export default Footer;
